<script setup lang="ts">
import { provide, ref } from 'vue';
import { RouterView } from 'vue-router';
import store from '@/store';
import { AtlasAppProvider } from '#atlaskit';
import { AppWrapper, FlagsContainer } from '#common';
import AtlasBanner from '#atlaskit/components/AtlasBanner.vue';
import AtlasLinkButton from '#atlaskit/components/AtlasButton/AtlasLinkButton.vue';

const dismissed = ref(localStorage.getItem('announcementDismissed') === 'true');

const handleDismiss = () => {
  localStorage.setItem('announcementDismissed', 'true');
  dismissed.value = true;
};

provide('store', store);
</script>

<template>
  <AtlasAppProvider>
    <div class="announcement-container">
      <AtlasBanner
        v-if="!dismissed"
        appearance="announcement"
      >
        Important announcement regarding the future of AMFJ -
        <AtlasLinkButton
          href="https://updates.assetmanagementforjira.com/announcements/important-announcement-regarding-the-future-of-asset-management-of-jira"
          target="_blank"
        >
          Read More
        </AtlasLinkButton>

        <AtlasLinkButton
          href="#"
          @click="handleDismiss"
        >
          Dismiss
        </AtlasLinkButton>
      </AtlasBanner>
    </div>
    <RouterView v-slot="{ Component, route }">
      <AppWrapper
        v-if="route.meta.appWrapper"
        :contained="!!route.meta.contained"
        :sidebar="!route.meta.noSidebar"
      >
        <component
          :is="Component"
          :key="`${route.path}-component`"
        />
      </AppWrapper>
      <component
        :is="Component"
        v-else
        :key="`${route.path}-component`"
      />
    </RouterView>

    <FlagsContainer />
  </AtlasAppProvider>
</template>

<style>
#ac-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
</style>

<style scoped>
.announcement-container :deep([role='alert']) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.announcement-container :deep(a) {
  line-height: 2;
}
</style>

<script setup lang="ts">
import Banner from '@atlaskit/banner';
import type { ComponentProps } from 'react';
import { applyPureReactInVue } from 'veaury';

defineProps<{
  appearance?: ComponentProps<typeof Banner>['appearance'];
}>();

const AtlasBanner = applyPureReactInVue(Banner);
</script>

<template>
  <AtlasBanner :appearance="appearance">
    <slot />
  </AtlasBanner>
</template>

import type {
  CommentPayload,
  DeleteBulkPayload,
  ExportPayload,
  ExportReportPayload,
  ImportPayload,
  ListParams,
  Payload,
} from "@/store/assets/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/assets";

// List Assets
const getAssets = (
  params: ListParams,
  isBlocking: boolean
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: params,
    headers: { isBlocking },
  };
  return apiClient.get(END_POINT, config);
};

const getAssetsPageNum = (
  params: ListParams,
  pageNum: number
): Promise<AxiosResponse> => {
  params.page = pageNum;
  const config: AxiosRequestConfig = {
    params: params,
  };
  return apiClient.get(END_POINT, config);
};

// Create Assets
const postAssets = (payload: Payload): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(END_POINT, payload, config);
};

const postSampleAsset = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`/sample-asset`, config);
};

// Get Asset
const getAsset = (
  id: string,
  notShowErrorMsg = false
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    headers: notShowErrorMsg
      ? { isBlocking: true, notShowErrorMsg: true }
      : { isBlocking: true },
  };
  return apiClient.get(`${END_POINT}/${id}`, config);
};

const getAssetComments = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/comments`);
};

const createComment = (
  id: string,
  payload: CommentPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/${id}/comments`, payload);
};

const updateComment = (
  id: number,
  payload: CommentPayload
): Promise<AxiosResponse> => {
  return apiClient.put(`/comments/${id}`, payload);
};

const deleteComment = (id: number): Promise<AxiosResponse> => {
  return apiClient.delete(`/comments/${id}`);
};

const getAssetHistory = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/history`);
};

const deleteAsset = (id: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.delete(`${END_POINT}/${id}`, config);
};

const updateAsset = (id: string, payload: Payload): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.put(`${END_POINT}/${id}`, payload, config);
};

const deleteBulkAssets = (
  payload: DeleteBulkPayload
): Promise<AxiosResponse> => {
  return apiClient.post(`${END_POINT}/delete`, payload);
};

const checkBulkAssets = (
  payload: DeleteBulkPayload
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`${END_POINT}/match`, payload, config);
};

// import
const importAssets = (payload: ImportPayload): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`/import-assets`, payload, config);
};

const importAssetStatus = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`import-assets/${id}`);
};

// import
const exportAssets = (payload: ExportPayload): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`/export-assets`, payload, config);
};

const exportReports = (
  payload: ExportReportPayload
): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`/export-reports`, payload, config);
};

// linked issues
const getLinkedIssues = (assetId: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { assetId } };
  return apiClient.get("/linked-issues", config);
};

// asset panel
const getAssetPanel = (originId: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { originId } };
  return apiClient.get("/asset-panel", config);
};

// asset attachments
const getAssetAttachments = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}/attachments`);
};

const deleteAssetAttachments = (
  id: string,
  assetId: string
): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${assetId}/attachments/${id}`);
};

// linked asset choices
const getLinkedAssetChoices = (): Promise<AxiosResponse> => {
  return apiClient.get(`linked-assets`);
};

export {
  getAssets,
  getAssetsPageNum,
  postAssets,
  postSampleAsset,
  getAsset,
  getAssetComments,
  createComment,
  updateComment,
  deleteComment,
  getAssetHistory,
  deleteAsset,
  updateAsset,
  deleteBulkAssets,
  importAssets,
  importAssetStatus,
  getLinkedIssues,
  getAssetPanel,
  exportAssets,
  getAssetAttachments,
  deleteAssetAttachments,
  exportReports,
  checkBulkAssets,
  getLinkedAssetChoices,
};

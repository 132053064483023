import { getDetails, postInstall } from '@/api/utils.api';
import type { AxiosResponse } from 'axios';
import { reactive } from 'vue';
import type { State } from './types';
import LogRocket from 'logrocket';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/vue';

const state: State = reactive({
  appDetails: {
    install_id: '',
    install_url: '',
    num_assets: '',
    num_asset_types: '',
    num_locations: '',
    created_at: '',
  },
});

// methods
const methods = {
  get(): void {
    getDetails().then((response: AxiosResponse) => {
      state.appDetails = response.data;

      // @ts-expect-error
      window.Beacon('identify', {
        'install-url': state.appDetails.install_url,
        'num-assets': state.appDetails.num_assets,
        'num-asset-types': state.appDetails.num_asset_types,
        'num-locations': state.appDetails.num_locations,
        'install-dt': state.appDetails.created_at,
      });

      // identify
      LogRocket.identify(state.appDetails.install_id, {
        name: state.appDetails.install_url,
      });

      posthog.identify(state.appDetails.install_id, {
        name: state.appDetails.install_url,
      });

      Sentry.setUser({
        id: state.appDetails.install_id,
        username: state.appDetails.install_url,
      });
    });
  },
  addInstall(): void {
    postInstall().then();
  },
};

export default {
  state,
  methods,
};

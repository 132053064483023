import type { Payload } from "@/store/reports/types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/reports";

// List
const getReports = (isBlocking: boolean): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking } };
  return apiClient.get(END_POINT, config);
};

// Create Report
const postReports = (payload: Payload): Promise<AxiosResponse> => {
  return apiClient.post(END_POINT, payload);
};

// Update Report
const updateReport = (id: string, payload: Payload): Promise<AxiosResponse> => {
  return apiClient.put(`${END_POINT}/${id}`, payload);
};

// Get Report
const getReport = (id: string): Promise<AxiosResponse> => {
  return apiClient.get(`${END_POINT}/${id}`);
};

// Delete Report
const deleteReport = (id: string): Promise<AxiosResponse> => {
  return apiClient.delete(`${END_POINT}/${id}`);
};

export { getReports, postReports, updateReport, getReport, deleteReport };

<script setup lang="ts">
import utils from '@/utils';
import { LinkButton } from '@atlaskit/button/new';
import type { ComponentProps, MouseEvent } from 'react';
import { applyPureReactInVue } from 'veaury';
import { computed } from 'vue';
import { useRouter, type RouteLocationRaw } from 'vue-router';

const props = defineProps<{
  appearance?: ComponentProps<typeof LinkButton>['appearance'];
  iconBefore?: ComponentProps<typeof LinkButton>['iconBefore'];
  to?: RouteLocationRaw;
  href?: string;
  disabled?: ComponentProps<typeof LinkButton>['isDisabled'];
  target?: ComponentProps<typeof LinkButton>['target'];
  rel?: ComponentProps<typeof LinkButton>['rel'];
}>();

const emit = defineEmits<{
  click: [];
}>();

const router = useRouter();
const href = computed(() => {
  if (props.to) {
    const { href } = router.resolve(props.to);
    return utils.getAtlassianComponentURL(href);
  }

  return props.href;
});

const handleClick = (e: MouseEvent) => {
  emit('click');
  if (!props.to) return;

  e.preventDefault();
  router.push(props.to);
};

const AtlasLinkButton = applyPureReactInVue(LinkButton);
</script>

<template>
  <AtlasLinkButton
    :href="href"
    :appearance="appearance"
    :isDisabled="disabled"
    :iconBefore="iconBefore"
    :target="target"
    :rel="rel"
    @click="handleClick"
  >
    <slot />
  </AtlasLinkButton>
</template>

export enum RouteName {
  HOME = 'settings-home',
  QR_CODE = 'settings-qr-code',
  FIELD_SYNC = 'settings-field-sync',
  API_AND_EVENTS = 'settings-api-and-events',
  INTEGRATIONS = 'settings-integrations',
  KANDJI = 'settings-kandji',
}

export enum QueryKey {
  SETTINGS = 'settings',
  JIRA_PROJECTS = 'jira-projects',
  JIRA_GROUPS = 'jira-groups',
  API_KEYS = 'api-keys',
  EVENTS = 'events',
  CUSTOM_FIELDS = 'custom-fields',
  INTUNE_SETTINGS = 'intune-settings',
  KANDJI_SETTINGS = 'kandji-settings',
}

export enum PageTab {
  GENERAL = 'general',
  QR_CODE = 'qr-code',
  FIELD_SYNC = 'field-sync',
  API_AND_EVENTS = 'api-and-events',
  INTEGRATIONS = 'integrations',
}

export enum EventSubscription {
  ASSET_CREATE = 'asset.create',
  ASSET_UPDATE = 'asset.update',
  ASSET_DELETE = 'asset.delete',
}

export const PAGE_TABS = [
  { key: PageTab.GENERAL, label: 'General', name: RouteName.HOME },
  { key: PageTab.QR_CODE, label: 'QR Code', name: RouteName.QR_CODE },
  { key: PageTab.FIELD_SYNC, label: 'Field Sync', name: RouteName.FIELD_SYNC },
  { key: PageTab.API_AND_EVENTS, label: 'API & Events', name: RouteName.API_AND_EVENTS },
  { key: PageTab.INTEGRATIONS, label: 'Integrations', name: RouteName.INTEGRATIONS },
];

/*
 * !IMPORTANT
 * We are progressively migrating to a DDD architecture.
 * Routes will be instatiated with the views they are refering.
 * This file will be then deleted once all routes here are fully migrated.
 */

import type { RouteRecordRaw } from 'vue-router';

const Home = () => import('@/components/HomeDynamic.vue');

// Accessories
const AccessoryDetail = () => import('@/components/accessories/AccessoryDetail.vue');

// Locations
const LocationEdit = () => import('@/components/locations/LocationEdit.vue');

// Alerts
const AlertCreate = () => import('@/components/alerts/AlertCreate.vue');
const AlertEdit = () => import('@/components/alerts/AlertEdit.vue');

// Assets
const AssetsHome = () => import('@/components/assets/AssetsHome.vue');
const AssetCreate = () => import('@/components/assets/NewAssetCreate.vue');
const AssetDetail = () => import('@/components/assets/AssetDetail.vue');
const AssetEdit = () => import('@/components/assets/AssetEdit.vue');
const AssetCopy = () => import('@/components/assets/AssetCopy.vue');
const AssetsImport = () => import('@/components/assets/AssetsImport.vue');
const AssetsImportStatus = () => import('@/components/assets/AssetsImportStatus.vue');
const AssetPanel = () => import('@/components/assets/AssetPanel.vue');
const AssetsExport = () => import('@/components/assets/AssetsExport.vue');
const AssetsExportTable = () => import('@/components/assets/AssetsExportTable.vue');

// Settings
const IntuneSettings = () => import('@/components/settings/IntuneSettings.vue');

// Reports
const ReportCreate = () => import('@/components/reports/ReportCreate.vue');
const ReportEdit = () => import('@/components/reports/ReportEdit.vue');

// Onboarding
const OnboardingCreateAsset = () => import('@/components/onboarding_v2/CreateAsset.vue');
const NewImportAssets = () => import('@/components/onboarding_v2/NewImportAssets.vue');

// Licenses
const LicenseDetail = () => import('@/components/licenses/LicenseDetail.vue');

const ROUTES: Array<RouteRecordRaw> = [
  {
    path: '/getting-started',
    name: 'Home',
    component: Home,
    meta: { atlaskit: true, appWrapper: true, noSidebar: true },
  },
  {
    path: '/accessories/:accessory_id',
    name: 'AccessoryDetail',
    component: AccessoryDetail,
    props: true,
  },
  // Licenses
  {
    path: '/licenses/:license_id',
    name: 'LicenseDetail',
    component: LicenseDetail,
    props: true,
  },
  // Alerts
  {
    path: '/alerts/create',
    name: 'AlertCreate',
    component: AlertCreate,
  },
  {
    path: '/alerts/:alert_id/edit',
    name: 'AlertEdit',
    component: AlertEdit,
    props: true,
  },
  // Assets
  {
    path: '/assets',
    name: 'Assets',
    component: AssetsHome,
  },
  {
    path: '/assets/create',
    name: 'AssetCreate',
    component: AssetCreate,
  },
  {
    path: '/assets/:asset_id',
    name: 'AssetDetail',
    component: AssetDetail,
    props: true,
  },
  {
    path: '/assets/:asset_id/edit',
    name: 'AssetEdit',
    component: AssetEdit,
    props: true,
  },
  {
    path: '/assets/copy',
    name: 'AssetCopy',
    component: AssetCopy,
  },
  {
    path: '/assets/import',
    name: 'AssetsImport',
    component: AssetsImport,
  },
  {
    path: '/assets/import/status',
    name: 'AssetsImportStatus',
    component: AssetsImportStatus,
  },
  {
    path: '/assets/export',
    name: 'AssetsExport',
    component: AssetsExport,
  },
  {
    path: '/assets/export-table',
    name: 'AssetsExportTable',
    component: AssetsExportTable,
  },
  {
    path: '/asset/new-import',
    name: 'ImportAsset',
    component: NewImportAssets,
    props: { showFooter: false },
  },
  {
    path: '/asset-panel',
    name: 'AssetPanel',
    component: AssetPanel,
  },
  // settings
  {
    path: '/intune-settings',
    name: 'IntuneSettings',
    component: IntuneSettings,
  },
  // Reports
  {
    path: '/reports/create',
    name: 'ReportCreate',
    component: ReportCreate,
  },
  {
    path: '/reports/:report_id/edit',
    name: 'ReportEdit',
    component: ReportEdit,
    props: true,
  },
  // onboarding
  {
    path: '/onboarding/import',
    name: 'OnboardingImportAsset',
    component: NewImportAssets,
    props: { includeSidebar: false },
  },
  {
    path: '/onboarding/create',
    name: 'OnboardingCreate',
    component: OnboardingCreateAsset,
  },
  {
    path: '/onboarding/locations/:location_id/edit',
    name: 'OnboardingLocationEdit',
    component: LocationEdit,
    props: (route) => ({
      includeSidebar: false,
      location_id: route.params.location_id,
    }),
  },
];

export default ROUTES;

import { defineStore } from 'pinia';
import type { GenericObject } from '@/types';
import type { OptionItem } from '@BaseUi';
import { getAssetType } from '../helpers';
import { ASSET_TYPES } from '../types';
import { requiredOptions } from './constants';
import type { DropdownFieldValue } from './types';

interface MappedAssets {
  assetValues: string[][];
  columnsWithEmptyValues: number[];
}

interface MappedHeader {
  header: string;
  type: ASSET_TYPES;
}

interface State {
  blankCellsInput: (string | number)[][];
  cellsWithErrors: Set<string>, 
  columnsWithDateErrors: Set<number>,
  columnsWithErrors: Set<number>,
  content: GenericObject[];
  dropdownFieldValues: Set<DropdownFieldValue>[];
  importQueueId: number;
  mappedOptions: OptionItem[],
  requiredColumnsBlankFields: Set<string>;
  requiredColumnsWithBlankFields: number[];
  resultsSelectedColumns: number[];
  selectedOptions: number[];
}

export const useAssetImportStore = defineStore('assetImport', {  
  state: (): State => {
    return {
      blankCellsInput: [],
      cellsWithErrors: new Set<string>(),
      columnsWithDateErrors: new Set<number>(),
      columnsWithErrors: new Set<number>(),
      content: [],
      dropdownFieldValues: [],
      importQueueId: -1,
      mappedOptions: [],
      requiredColumnsBlankFields: new Set<string>(),
      requiredColumnsWithBlankFields: [],
      resultsSelectedColumns: [],
      selectedOptions: [],
    }
  },
  getters: {
    headers(): MappedHeader[] {
      if (!this.content.length) return [];

      const assetSample = this.content[0];

      const headers: MappedHeader[] = Object.keys(assetSample).map(key => ({
        header: key,
        type: getAssetType(assetSample[key])
      }));
      
      return headers;
    },
    rows(): number {
      return this.content.length;
    },
    columns() {
      return this.headers.length;
    },
    mappedContent(): MappedAssets {
      const mappedAssets: MappedAssets = {
        assetValues: [],
        columnsWithEmptyValues: [],
      };

      if (!this.content.length) {
        return mappedAssets
      }

      const columnsWithEmptyValue: number[] = [];
    
      this.content.map((asset) => {
        this.blankCellsInput.push([]);

        const mappedValues: string[] = []; 
        const values = Object.values(asset);
    
        values.forEach((item, index) => {
          if (!item.length) {
            columnsWithEmptyValue.push(index);
          }
    
          item.length <= 27
            ? mappedValues.push(item)
            : mappedValues.push(item.slice(0, 23) + ' ...');
        });

        mappedAssets.assetValues.push(mappedValues);
      });
      mappedAssets.columnsWithEmptyValues = [...new Set(columnsWithEmptyValue)];

      return mappedAssets;
    },

    requiredOptionsIds(): number[] {
      return requiredOptions.map(({ id }) => id);
    },

    isRequiredOptionsSelected(): boolean {
      if (!this.selectedOptions.length) return false;
      
      return this.requiredOptionsIds.every((id) => this.selectedOptions.includes(id));
    },

    mappedColumns(): number {
      return this.selectedOptions.length;
    },
  },
  actions: {
    isColumnSelected(columnIndex: number) {
      return this.resultsSelectedColumns.includes(columnIndex);
    },
    
    isRequiredColumn(columnIndex: number) {
      const selectedHeaderMatch = this.getOptionIdFromSelectedColumn(columnIndex)
    
      return this.requiredOptionsIds.includes(selectedHeaderMatch);
    },

    getOptionIdFromSelectedColumn(columnIndex: number) {
      const selectedColumnIndex = this.resultsSelectedColumns.indexOf(columnIndex);
      const optionIdMatch = this.selectedOptions[selectedColumnIndex];

      return optionIdMatch;
    },
  }
});
import { reactive } from "vue";
import type { ApiKey, State } from "./types";

import { createApiKey, deleteAPIKey, getApiKeys } from "@/api/apikey.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  apiKey: {
    id: "",
    api_key: "",
  },
});

// methods
const methods = {
  async list(): Promise<void> {
    await getApiKeys().then((response: AxiosResponse<ApiKey>) => {
      state.apiKey = response.data;
    });
  },
  create(): void {
    createApiKey().then(() => {
      this.list();
    });
  },
  delete(id: string): void {
    deleteAPIKey(id).then(() => {
      state.apiKey = {
        id: "",
        api_key: "",
      };
    });
  },
};

export default {
  state,
  methods,
};

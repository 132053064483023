import type { AxiosRequestConfig, AxiosResponse } from "axios";
import apiClient from "./client";

const END_POINT = "/apikeys";

// List
const getApiKeys = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.get(END_POINT, config);
};

// Create API Key
const createApiKey = (): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.post(`${END_POINT}`, config);
};

const deleteAPIKey = (id: string): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = { headers: { isBlocking: true } };
  return apiClient.delete(`${END_POINT}/${id}`, config);
};

export { createApiKey, deleteAPIKey, getApiKeys };

import { reactive, type Ref } from "vue";
import type { Report, Payload, State, TableColumn } from "./types";

import auth from "@/store/auth";
import {
  deleteReport,
  getReport,
  getReports,
  postReports,
  updateReport,
} from "@/api/reports.api";
import type { AxiosResponse } from "axios";

// state
const state: State = reactive({
  reports: [],
  currentReport: {
    id: "",
    name: "",
    fields_to_display: [],
    filter_conditions: [],
  },
  tableColumns: [
    { key: "asset", label: "Asset", sortable: true },
    { key: "name", label: "Name", sortable: true },
    { key: "asset_type", label: "Type", sortable: true },
    { key: "location", label: "Location", sortable: true },
    { key: "asset_loanee", label: "Loanee", sortable: true },
  ],
  tableColumnsReport: [
    { key: "name", label: "Name", sortable: true },
    { key: "update", label: "", sortable: false },
  ],
  didCreateFirstReport: false,
});

// methods
const methods = {
  list(): void {
    getReports(true).then((response: AxiosResponse<Report[]>) => {
      state.reports = response.data;
    });
  },
  create(formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    if (state.reports.length === 0) {
      state.didCreateFirstReport = true;
    }
    postReports(payload).then(() => {
      this.list();
      this.reset();
    });
  },
  update(id: string, formData: Ref): void {
    const payload: Payload = this.getCreatePayload(formData);
    updateReport(id, payload).then((response: AxiosResponse<Report>) => {
      state.currentReport = response.data;
      this.reset();
    });
  },
  getCreatePayload(formData: Ref): Payload {
    const filter_conditions = formData.value.filter_conditions;
    const fields_to_display = formData.value.fields_to_display;
    const payload: Payload = {
      fields_to_display: fields_to_display.toString(),
      filter_conditions: JSON.stringify(filter_conditions),
      name: formData.value.name,
      updated_by: auth.state.atlassianUsername,
    };
    return payload;
  },
  get(id: string): void {
    getReport(id).then((response: AxiosResponse<Report>) => {
      state.currentReport = response.data;
    });
  },
  setCurrentReport(id: string): void {
    state.reports.forEach((report: Report) => {
      if (report.id === id) {
        state.currentReport = report;
      }
    });
  },
  delete(id: string): void {
    deleteReport(id).then(() => {
      state.currentReport = {
        id: "",
        name: "",
        fields_to_display: [],
        filter_conditions: [],
      };
    });
  },
  updateDidCreateFirstReport(): void {
    state.didCreateFirstReport = false;
  },
  updateTableColumns(cols: Array<string>): void {
    const fieldsToDisplay: Array<string> = [];
    cols.forEach((col: string) => {
      fieldsToDisplay.push(col);
    });
    state.currentReport.fields_to_display = fieldsToDisplay;
  },
  getExtraFields(): Array<TableColumn> {
    const fields_to_display = state.currentReport.fields_to_display;
    const fieldsToDisplayForRender: Array<TableColumn> = [];
    fields_to_display.forEach((col: string) => {
      fieldsToDisplayForRender.push({
        key: col,
        label: col,
        sortable: true,
      });
    });
    return fieldsToDisplayForRender;
  },
  reset(): void {
    state.currentReport = {
      id: "",
      name: "",
      fields_to_display: [],
      filter_conditions: [],
    };
  },
};

export default {
  state,
  methods,
};
